<template>
  <section>
    Frequently Asked Questions is not built out
  </section>
</template>

<script>

export default {
  name: 'Faq'
}
</script>

<style scoped>

</style>
